<template>
    <div class="header-outer-body" :style="{ 
            'background-color': 
                theme === 'dark-theme' && 
                ($route.name && $route.name.includes('ExchangeView')) 
                ? 'var(--primary100)' 
                : 'var(--primary-color)' 
            }">
        <div class="header-body">
            <div @click="navigateToRoute('HomeView')" class="cursor-pointer header-logo"
                v-if="$route.name !== 'UnderMaintenance' && !$route.query.id">
                <img src="@/assets/images/header/hataLogo.png" width="132px" />
                <span v-if="$route.name == 'OtcView' || $route.query.id">Global</span>
            </div>

            <div class="header-logo" v-else>
                <img src="@/assets/images/header/hataLogo.png" width="132px" />
                <span v-if="$route.query.id">Global</span>
            </div>


            <div class="nav-wrapper" v-if="!isLogin && ($route.name !== 'UnderMaintenance' && !$route.query.id)">
                <HeaderDropdown :title="$t('Header.Market')" :options="marketOptions" @action="headerRoute" />

                <HeaderRoute :title="$t('Header.OTC')" routeName="OtcView"
                    v-if="$store.state.preferences.platform == '2'" />

                <a href="https://explore.hata.io/" target="_blank">
                    <div class="header-title">
                        {{ $t("Header.Explore") }}
                    </div>
                </a>

                <HeaderDropdown :title=" languageHeader(currentLanguage)" :options="languageOptions"
                    :isLanguage="true" />

                <button class="auth-button" @click="authRoute('SignInView')">
                    {{ $t("Header.SignIn") }}
                </button>

                <button class="auth-button" @click="authRoute('SignUpView')">
                    {{ $t("Header.SignUp") }}
                </button>
            </div>

            <div class="nav-wrapper" v-else-if="isLogin && ($route.name !== 'UnderMaintenance' && !$route.query.id)">
                <HeaderDropdown :title="$t('Header.Home')" :options="homeOptions" :isPlatform="true"
                    @action="headerRoute" />

                <HeaderDropdown :title="$t('Header.Market')" :options="marketOptions" @action="headerRoute" />

                <HeaderRoute :title="$t('Header.OTC')" routeName="OtcView"
                    v-if="$store.state.preferences.platform == '2'" />

                <HeaderRoute :title="$t('Header.Wallet')" routeName="Wallet" />

                <HeaderDropdown :title="languageHeader(currentLanguage)" :options="languageOptions"
                    :isLanguage="true" />

                <HeaderDropdown :options="profileOptions" :isProfile="true" />

                <router-link :to="generateRouteLink('NotificationView')">
                    <button class="header-noti"
                        :class="currentRoute && currentRoute.includes('ExchangeView') ? 'themed' : ''">
                        <!-- <img src="@/assets/images/header/sidebar/bell.svg" width="30px"/> -->
                        <InlineSvg :src="require(`@/assets/images/header/sidebar/bell.svg`)" width="30px" />
                        <div class="drawer-noti-dot" v-if="notification > 0">{{ notification }}</div>
                    </button>
                </router-link>
            </div>

            <!-- MOBILE -->
            <div class="burger" v-if="$route.name !== 'UnderMaintenance' && $route.query.id">
                <button
                    @click="$route.path.includes('payment') && $route.query.page == '4' ? returnToMerchant() : $store.dispatch('preferences/setReturnMerchant', !$store.state.preferences.setReturnMerchant)">
                    <img src="@/assets/images/modal/white-close-circle.png" width="24" />
                </button>
            </div>

            <div class="burger" v-else-if="$route.name !== 'UnderMaintenance' && !$route.query.id">
                <button @click="$store.dispatch('preferences/setOpenSidebar', !$store.state.preferences.showSideBar)">
                    <img src="@/assets/images/header/sidebarIcon.svg" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import HeaderRoute from "./HeaderRoute.vue";
import HeaderDropdown from "./HeaderDropdown.vue";

export default {
    components: {
        HeaderRoute,
        HeaderDropdown,
    },

    data() {
        return {
            homeOptions: [
                { 
                    icon: "header/sidebar/usd.svg",
                    title: this.$t("Header.Global"), 
                    value: "2", 
                },
                { 
                    icon: "header/sidebar/myr.svg",
                    title: this.$t("Header.Malaysia"), 
                    value: "1", 
                },
            ],
            marketOptions: [
                { title: this.$t("wallet.instantBuySell"), value: "MarketView", icon: "buy_crypto.svg"},
                { title: this.$t("Header.Exchange"), value: "ExchangeView", icon: "exchange.svg"}
            ],
            profileOptions: [
                { title: this.$t("Header.Profile"), value: "ProfileView", icon: "dropdown-profile.svg"},
                { title: this.$t("Profile.settings"), value: "SettingView", icon: "dropdown-settings.svg"},
                { title: this.$t("Profile.security"), value: "SecurityView", icon: "dropdown-security.svg"},
            ],
            languageOptions: [
                {title: this.$t('Language.English'), value: "en"},
                {title: this.$t('Language.Malay'), value: "ms"},
                {title: this.$t('Language.Chinese'), value: "zh"}
            ],
            currentLanguage: ""
        }
    },

    computed: {
        isLogin() {
            return this.$store.state.auth.isLoggedIn;
        },

        theme() {
            return this.$store.state.preferences.theme;
        },

        notification() {
            return this.$store.state.user.notification_count;
        },

        currentRoute() {
            return this.$route.name;
        }
    },

    mounted(){
        this.currentLanguage = localStorage.getItem('lang')
    },

    methods: {
        headerRoute(value) {
            this.navigateToRoute(value);
        },

        authRoute(route) {
            if(this.$route.path != route) {
                this.navigateToRoute(route);
                localStorage.removeItem("data");
            }
        },

        languageHeader(value){
            if(value === 'en'){
                return "EN"
            } else if(value === 'ms'){
                return "BM"
            } else if(value === 'zh'){
                return "中文"
            }
        }
    }
}
</script>

<style>
.header-outer-body {
    background-color: var(--primary-color);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    z-index: 1;
}

.header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 10;
    color: var(--gray40);
    font-size: 16px;
    height: 87px;
    max-width: 1224px;
    width: 100%;
    margin: 0% 5%;
}

.auth-button {
    width: 110px;
    border: 1px solid var(--white);
    background-color: var(--white);
    color: var(--primary-color);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-weight: 900;
}

.auth-button:first-of-type {
    border: 1.5px solid var(--white);
    background-color: transparent;
    margin-left: 25px;
    color: var(--white);
    background-color: transparent;
    margin-right: 25px;
}

.nav-wrapper {
    color: var(--white);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-title,
.active-header-title {
    padding: 15px 25px;
    text-align: center;
    font-weight: 500;
    cursor: pointer;
}

.header-title {
    color: var(--primary20) !important;
}

.header-title:hover {
    color: var(--white) !important;
}

.nav-wrapper > .header-title:hover,
.nav-wrapper > .active-header-title:hover {
    color: white !important;
}

.active-header-title,
.active-header-title .v-icon {
  color: var(--white) !important;
}

.header-noti {
    position: relative;
    padding-left: 25px;
}

.header-noti .drawer-noti-dot {
    min-width: 20px;
    font-size: 10px;
    height: 15px;
    top: 3px;
}

.dark-theme .header-noti.themed path{
    fill: var(--primary-color)
}

/* MOBILE NAV */
.burger {
    display: none;
}

.burger.btn-check:focus + .btn-secondary,
.btn-secondary:focus,
.btn-secondary {
    padding: 0px !important;
    box-shadow: none !important;
    border-color: transparent !important;
    background-color: transparent !important;
}

#sidebar-1 {
  width: 240px !important;
  background-color: var(--primary-color) !important;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header {
  flex-direction: row-reverse !important;
}

.b-sidebar.b-sidebar-right > .b-sidebar-header .close {
  box-shadow: none !important;
  border-color: transparent !important;
  background-color: transparent !important;
  color: var(--gray60) !important;
  margin-left: auto;
  margin-right: unset !important;
  font-size: 36px !important;
}

.mobile-nav-wrapper {
    padding: 0.5rem;
}

.mobile-nav-wrapper .header-title,
.mobile-nav-wrapper .active-header-title {
  font-size: 16px;
  padding: 12px 3px !important;
  text-decoration: none;
  border-bottom: 1px solid var(--tertiary-color);
  text-align: left;
}

.mobile-nav-wrapper > .active-header-title {
    color: var(--white);
}

.mobile-nav-wrapper .auth-button {
    width: 100%;
    margin: 1rem 0rem;
    height: 50px;
}

@media only screen and (max-width: 1200px) { 
    .header-body {
        position: unset;
        height: 70px;
    }

    .header-body .cursor-pointer > img {
        width: 100px;
    }

    .burger {
        display: flex;
    }

    .nav-wrapper {
        display: none;
    }

    .header-title,
    .active-header-title {
        text-align: center;
    }
}
</style>