<template>
    <div 
        class="m-sidebar-container" 
        id="sidebar" 
        :class="isHome && owned_platform == 0 ? 'd-none' : ''"
    >
        <div class="m-sidebar-title">{{ title }}</div>

        <span
            v-for="item, index in data"
            :key="index"
            class="position-relative"
        >
            <a 
                class="m-sidebar-item"
                :href="item.link"
                target="_blank"
                v-if="item.isNewTab"    
            >
                <div class="m-sidebar-box">
                    <InlineSvg 
                        :src="require(`@/assets/images/header/sidebar/${item.icon}`)"
                        width="30px"
                    />

                    <div class="m-sidebar-text">{{ item.title }}</div>
                </div>
            </a>

            <div
                v-else-if="item.switchable"
                class="m-expandable-sidebar-item"
            >
                <v-expansion-panels 
                    v-model="panel[0]"
                    v-if="owned_platform >= 3"
                >
                    <v-expansion-panel>
                        <v-expansion-panel-title  @click.stop="handleTitleClick">
                            <div class="m-sidebar-box">
                                <InlineSvg 
                                    :src="require(`@/assets/images/header/sidebar/${selected_platform == 1 ? 'myr' : 'usd'}.svg`)"
                                    width="30px"
                                />

                                <div class="m-sidebar-text">
                                    {{ selected_platform == 1 ? $t("Header.Malaysia") : $t("Header.Global") }}
                                </div>
                            </div>

                            <template v-slot:actions="{ expanded }">
                                <v-icon :class="expanded ? 'rotate-icon' : 'rotate-icon-reverse'">
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text>
                            <div class="m-sidebar-box opacity70" @click="switchPlatform(selected_platform == 1 ? 2 : 1); panel = [];">
                                <div style="width: 30px" class="d-flex justify-content-center">
                                    <img 
                                        :src="require(`@/assets/images/header/sidebar/${selected_platform == 1 ? 'usd' : 'myr'}.svg`)"
                                    />
                                </div>
                                
                                <div class="m-sidebar-text">
                                    {{ selected_platform == 1 ? $t("Header.Global") : $t("Header.Malaysia") }}
                                </div>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

                <div
                    class="m-sidebar-item"
                    :class="currentRoute && currentRoute.includes('GlobalView') ? 'm-sidebar-active-item' : ''"
                    @click="navigateToRoute('GlobalView')"
                    v-else-if="owned_platform != 0"
                >
                    <div class="m-sidebar-box">
                        <InlineSvg 
                            :src="require(`@/assets/images/header/sidebar/${selected_platform == 1 ? 'myr' : 'usd'}.svg`)"
                            width="30px"
                        />
                        
                        <div class="m-sidebar-text">
                            {{ selected_platform == 1 ? $t("Header.Malaysia") : $t("Header.Global") }}
                        </div>
                    </div>
                </div>
            </div> 

            <div class="m-expandable-sidebar-item" v-else-if="item.language">
                <v-expansion-panels v-model="panel[1]">
                    <v-expansion-panel>
                        <v-expansion-panel-title  @click.stop="">
                            <div class="m-sidebar-box">
                                <InlineSvg 
                                :src="require(`@/assets/images/profile/language.svg`)"
                                width="30px"
                                />
                                <div class="m-sidebar-text">
                                    {{ languageHeader(currentLanguage) }}
                                </div>
                            </div>

                            <template v-slot:actions="{ expanded }">
                                <v-icon :class="expanded ? 'rotate-icon' : 'rotate-icon-reverse'">
                                    mdi-chevron-down
                                </v-icon>
                            </template>
                        </v-expansion-panel-title>
                        <v-expansion-panel-text v-for="item, index in languageOptions" :key="index" :class="item.selectable ? '' : 'd-none'" @click="changeLanguage(item.value)">
                            <div class="m-sidebar-box opacity70">
                                <div style="width: 30px">
                                </div>
                                <div class="m-sidebar-text">
                                     {{item.title}}
                                </div>
                            </div>
                        </v-expansion-panel-text>
                    </v-expansion-panel>
                </v-expansion-panels>

            </div>
            
            <router-link :to="generateRouteLink(item.link)" v-else>
            <div
                class="m-sidebar-item"
                :class="currentRoute && currentRoute.includes(item.link) ? 'm-sidebar-active-item' : ''"
            >
                <div class="m-sidebar-box">
                    <InlineSvg 
                        :src="require(`@/assets/images/header/sidebar/${item.icon}`)"
                        width="30px"
                    />
                    
                    <div class="m-sidebar-text">{{ item.title }}</div>
                </div>
            </div>
        </router-link>

            <div class="red-dot" v-if="item.isKyc && $store.state.user.kyc_status !== 'approved'"></div>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String },
        data: { type: Array },
        switchable: { type: Boolean, default: false },
        isHome: { type: Boolean, default: false },
        language:  {type: Boolean, default: false}
    },

    data() {
        return {
            panel: [0, 1],
            toggle: this.$store.state.preferences.platform == 2 ? true : false,
            languageOptions: [
                {title: this.$t('Language.English'), selectable: this.$i18n.locale === 'en' ? false : true, value: 'en'},
                {title: this.$t('Language.Malay'), selectable: this.$i18n.locale === 'ms' ? false : true, value: 'ms'},
                {title: this.$t('Language.Chinese'), selectable: this.$i18n.locale === 'zh' ? false : true, value: 'zh'}
            ],
            currentLanguage: ""
        }
    },

    computed: {
        selected_platform () {
            return this.$store.state.preferences.platform;
        },

        owned_platform() {
            return this.$store.state.user.platform;
        },

        currentRoute() {
            return this.$route.name;
        },
    },

    mounted(){
        this.currentLanguage = localStorage.getItem('lang')
    },

    methods: {
        switchPlatform(value) {
            this.$store.dispatch("preferences/setPlatform", value);
            this.navigateToRoute("GlobalView");
        },
        
        handleTitleClick(event) {
            if (event.target.className === "v-expansion-panel-title__overlay") {
                this.navigateToRoute("GlobalView");              
            }
        },

        languageHeader(value){
            if(value === 'en'){
                return this.$t('Language.English')
            } else if(value === 'ms'){
                return this.$t('Language.Malay')
            } else if(value === 'zh'){
                return this.$t('Language.Chinese')
            }
        },

        changeLanguage(value){
            if(this.$store.state.auth.isLoggedIn){
                this.$store.dispatch("user/setLanguage", {locale: value}).then(() => {
                this.$router.go();
            });
            }else{
                localStorage.setItem('lang', value);
                this.$router.go()
            }
        }
    }
}
</script>

<style>
.m-sidebar-container {
    display: flex;
    flex-direction: column;
    /* overflow-y: auto; */
}

.m-sidebar-container > span {
    display: flex;
    flex-direction: row;
}

.m-sidebar-container:not(:first-child) {
    margin-top: 1.5rem;
}

.m-sidebar-container:last-of-type {
    margin-bottom: 1.5rem;
}

.m-sidebar-title {
    color: var(--primary10);
    font-size: 14px;
}

.m-sidebar-item,
.m-expandable-sidebar-item .v-expansion-panel-title,
.m-expandable-sidebar-item .v-expansion-panel-text__wrapper {
    display: flex;
    padding: 10px 0px !important;
    opacity: 1;
    min-height: auto;
    font-size: 16px;
}

.m-sidebar-box {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.m-sidebar-text {
    color: var(--white);
    margin-left: 8px;
    text-transform: capitalize;
    font-family: "Avenir";
}

.m-sidebar-active-item .m-sidebar-text {
    color: var(--tertiary-color);
}

.m-sidebar-active-item svg path {
    fill: var(--tertiary-color);
}

.m-sidebar-active-item svg #cryptoIcon {
    fill: var(--primary-color);
}

.m-sidebar-active-item svg #profileIcon {
    fill: var(--primary-color);
}

.m-sidebar-active-item svg #globalIcon {
    stroke: var(--tertiary-color);
}

.m-sidebar-active-item svg #exchangeIcon {
    fill: transparent;
    stroke: var(--white) !important;
}

.m-expandable-sidebar-item {
    width: 100%;
    overflow-x: hidden;
}

.m-expandable-sidebar-item .v-expansion-panel,
.m-expandable-sidebar-item .v-expansion-panel-title__overlay {
    background-color: transparent !important;
}

.m-expandable-sidebar-item .v-expansion-panel-title__icon .v-icon {
    color: var(--white) !important;
}

.m-expandable-sidebar-item .v-expansion-panel--active > .v-expansion-panel-title:not(.v-expansion-panel-title--static) {
    min-height: auto;
}

.m-expandable-sidebar-item .v-expansion-panel__shadow {
    box-shadow: none;
}

#sidebar .red-dot {
    right: 0;
    top: 19px;
}
</style>